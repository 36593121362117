import Cookies from 'js-cookie'
//
import { CartClient } from '@/lib/cart-client'
import * as types from '@/redux/actionTypes'
import { updateCartModal } from '@/redux/actions/uiAction'
import { getCurrentUserDomain } from '@/lib/helpers'
import { getRegion, setCartLocale, getCurrentRegion, getCartLocale } from '@/lib/region-helper'
import { getCartCurrencySymbol } from '@/lib/cart-helper'
import { trans } from '@/lib/locale-helper'
import { getVariantMetafields } from './productAction'
import { getGroupNameInfo, getDiscountIdsFromCartItems, getCategoryById, getProductBySku } from '@/lib/product-helper'

let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

const orderingCartItems = (newCartItems = [], existingCart = []) => {
  const wishlistItemsLen = existingCart?.filter((item) => item?.added_to_wishlist)?.length
  const updatedCartLength = wishlistItemsLen + newCartItems?.length

  let newCart = new Array(updatedCartLength)
  let j = 0

  if (existingCart.length == 0) {
    newCart = newCartItems
  } else {
    // Adding product to cart
    newCartItems.forEach((product) => {
      const cartProduct = existingCart.find((item) => (item?.sku == product.sku && !item?.added_to_wishlist));
      if (!cartProduct) {
        existingCart = [...existingCart, product]
      }
    });

    //// Ordering the items in cart

    for (let i = 0; i < existingCart.length; i++) {
      if (!existingCart[i].added_to_wishlist) {
        const productItem = newCart.find(
          (item) => item?.sku == existingCart[i].sku && !item.added_to_wishlist
        )
        if (!productItem) {
          const temp = newCartItems.filter((item) => item.sku == existingCart[i].sku)

          for (let k = temp.length - 1; k >= 0; k--) {
            newCart[j++] = { ...temp[k], added_to_wishlist: false }
          }
        }
      } else {
        newCart[j++] = existingCart[i]
      }
    }
  }

  return newCart
}

export const getCart = () => async (dispatch) => {
  dispatch({ type: types.LOAD_CART_REQUEST })
  dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })
  let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

  try {
    const { data } = await CartClient.get(`/cart`)

    let newCartItems = data?.line_items?.physical_items.map((item) => ({
      ...item,
      added_to_wishlist: false,
    }))

    const newCart = orderingCartItems(newCartItems,newCartItems)

    dispatch({
      type: types.LOAD_CART_SUCCESS,
      payload: {
        ...data,
        all_cart_items: newCart,
      },
    })

    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here
    if (err.response && err.response.status == 400) {
      document.cookie =
        'bc_cartId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.gorewear.com; path=/;'
    }
    dispatch({ type: types.LOAD_CART_FAILURE })
    console.log('getCart err', err)
    Cookies.remove('bc_cartId')
    Cookies.remove(`bc_cartId_${country.storeSource.toLowerCase()}`)
    Cookies.remove('ssCartProducts')
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const getAbandonedCart = (t) => async (dispatch) => {
  dispatch({ type: types.LOAD_CART_REQUEST })

  try {
    const { data } = await CartClient.post(`/abandoned/cart`, {
      data: { token: t },
    })
    dispatch({
      type: types.LOAD_CART_SUCCESS,
      payload: data,
    })
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    if (country?.storeSource == 'EU') {
      setCartLocale(country)
      const productList = data?.line_items?.physical_items
      if (productList && productList?.length > 0) {
        let items = []
        for (const obj of productList) {
          let variantMetafields = await getVariantMetafields(obj?.product_id, obj?.variant_id)
          let listPrice = variantMetafields
            .find((varnt) => varnt.key == `${country.isoCode.toLowerCase()}_sale_price`)
            ?.value?.replace(/[{""}]/g, '')

          if (listPrice == 'null') {
            listPrice = variantMetafields
              .find((varnt) => varnt.key == `${country.isoCode.toLowerCase()}_price`)
              ?.value?.replace(/[{""}]/g, '')
          }
          items.push({
            currencyCode: country?.isoCode,
            listPrice: listPrice,
            name: obj?.name,
            productId: obj?.product_id,
            variantId: obj?.variant_id,
            quantity: obj?.quantity || 1,
            itemId: obj?.id,
          })
        }
        const { data: newData } = await CartClient.put(`/cart/update`, {
          items,
        })
        await dispatch(getCart())
        dispatch(updateCartModal(true))
      }
    } else {
      await dispatch(getCart())
      dispatch(updateCartModal(true))
    }
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.LOAD_CART_FAILURE })
    throw err
  }
}

export const updateItemQty = (itemId, quantity) => async (dispatch) => {
  dispatch({ type: types.UPDATE_ITEM_QTY_REQUEST })

  try {
    dispatch({
      type: types.UPDATE_ITEM_QTY_SUCCESS,
      payload: { itemId, quantity },
    })

    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.UPDATE_ITEM_QTY_FAILURE })
    console.log('updateItemQty err', err)
  }
}

export const prepareCartForCheckout = async (item, cartData) => {
  try {
    let productReview = cartData.reviews
    let productData = cartData
    let currentReg = getCurrentRegion()
    let checkoutLocale = getCartLocale(currentReg.domain)
    let checkoutProduct = await getProductBySku(cartData.sku, false, true)
    let gender =
      productData.custom_fields.find((field) => {
        return field.name === 'gender'
      })?.value || ''
    let sport =
      productData.custom_fields.find((field) => {
        return field.name === 'sport_activity'
      })?.value || ''
    let category_1 = productData.categories[2]
      ? getCategoryById(productData.categories[2])?.name
      : ''
    let category_2 = productData.categories[3]
      ? getCategoryById(productData.categories[3])?.name
      : ''

    let selectedVariant = checkoutProduct.variants.find(variant => variant.id == item[0].variantId)
    
    let salePrice = selectedVariant.sale_price != 'null' ? selectedVariant.sale_price : selectedVariant.price
    let price = selectedVariant.price
    if (checkoutLocale.domain != 'en-us') {
      let metaSalePrice = selectedVariant.metafields.find(field => field.key == `${checkoutLocale.isoCode.toLowerCase()}_sale_price`)?.value?.replace(/[{""}]/g, '')
      let metaPrice = selectedVariant.metafields.find(field => field.key == `${checkoutLocale.isoCode.toLowerCase()}_price`)?.value?.replace(/[{""}]/g, '')
      
      salePrice = metaSalePrice != 'null' ? metaSalePrice : metaPrice
      price = metaPrice
    }

    let itemObj = {
      name: productData.name,
      id: productData.sku,
      quantity: 1,
      price: salePrice,
      item_category: gender,
      item_category2: sport,
      item_category3: category_1,
      item_category4: category_2,
      item_brand: 'GOREWEAR',
      variant: selectedVariant.sku,
      item_regular_price: price,
      item_size: selectedVariant.option_values.find(option => option.option_display_name.toLowerCase() == 'size')?.label,
      item_rating: productReview.averageScore ? String(productReview.averageScore) : '0',
      item_review_count: productReview.totalReview ? String(productReview.totalReview) : '',
      item_gender: gender,
      item_color: selectedVariant.option_values.find(option => option.option_display_name.toLowerCase() == 'color')?.label,
      item_sport: sport,
      item_upc: selectedVariant.upc,
      item_stock: checkoutProduct.availability,
    }
    
    let cartModifier = checkoutProduct.modifiers.find(option => option.display_name.toLowerCase() == 'cart data')
    if(cartModifier) {
      return {
        option_id: cartModifier.id,
        option_value: JSON.stringify(itemObj)
      }
    }
    return false
  } catch (error) {
    console.log(error.message)
  }
}

export const addToCart = (item, product) => async (dispatch, getState) => {
  let { cartReducer } = getState()
  let cartModifier = await prepareCartForCheckout(item, product)
  if(cartModifier && Object.keys(cartModifier).length > 0) {
    item[0].option_selections.push(cartModifier)
  }
  dispatch({ type: types.ADD_CART_REQUEST })

  console.log('add to cart', item)

  try {
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    const { data, Header } = await CartClient.post(`/cart`, {
      items: item,
      channel_id: process.env.CUSTOM_CHANNEL_ID,
      user_locale_cache: getCurrentUserDomain(),
    })
    const cartCookie = Header['Set-Cookie']
    document.cookie = cartCookie
    const localizedCartCookie = cartCookie.replace(
      'bc_cartId',
      `bc_cartId_${country.storeSource.toLowerCase()}`
    )
    document.cookie = localizedCartCookie

    let newCartItems = data.line_items.physical_items.map((item) => ({
      ...item,
      added_to_wishlist: false,
    }))

    newCartItems = orderingCartItems(newCartItems,cartReducer?.all_cart_items)

    dispatch({
      type: types.ADD_CART_SUCCESS,
      payload: {
        ...data,
        all_cart_items:newCartItems,
      },
    })
    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here
    console.log('the error', err.response)

    if (err.response && err.response.status == 422 && err.response.data) {
      err.response.data.message = trans('currently-out-of-stock', 'Currently out of stock')
    }
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('addToCart err', err)
    throw err
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const updateToCart = (item) => async (dispatch, getState) => {
  let { cartReducer } = getState()
  dispatch({ type: types.UPDATE_CART_REQUEST })
  dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })
  try {
    const { data } = await CartClient.put(`/cart`, item)
    let allCartItems = [...cartReducer.all_cart_items]

    let updatedAllCartItems = data?.line_items?.physical_items?.map((cartItem) => {
      let existingItem = allCartItems?.find((item) => item?.id == cartItem?.id)

      if(existingItem){
        return {
          ...cartItem,
          added_to_wishlist:existingItem?.added_to_wishlist
        }
      }
      return cartItem
    })

    updatedAllCartItems = orderingCartItems(
      updatedAllCartItems,
      cartReducer?.all_cart_items
    )

    dispatch({
      type: types.UPDATE_CART_SUCCESS,
      payload: {
        ...data,
        all_cart_items: updatedAllCartItems,
      },
    })

    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here

    dispatch({ type: types.UPDATE_CART_FAILURE })
    console.log('updateToCart err', err)
    throw err
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const deleteToCart =
  (item, existingCart = []) =>
  async (dispatch, getState) => {
    let { cartReducer } = getState()
    dispatch({ type: types.DELETE_CART_REQUEST })
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })

    if (existingCart?.length === 0) {
      existingCart = cartReducer.all_cart_items
    }

  try {
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    const cartCookie = Cookies.get('bc_cartId')

    if (cartCookie) {
      const { data } = await CartClient.delete(`/cart`, { data: { itemId: item.id } })

      const newLineItems = data?.line_items?.physical_items || []

        let newAllCartItems = newLineItems?.map((cartItem) => {
          const existingItem = cartReducer?.all_cart_items?.find((item) => item?.id == cartItem?.id)

          if (existingItem) {
            return {
              ...cartItem,
              added_to_wishlist: existingItem?.added_to_wishlist,
            }
          }
          return cartItem
        })

        newAllCartItems = orderingCartItems(newAllCartItems, existingCart)

        dispatch({
          type: types.DELETE_CART_SUCCESS,
          payload: {
            data,
            newAllCartItems,
          },
        })
      }

    if (!cartCookie) {
      Cookies.remove(
        `bc_cartId_${country.storeSource.toLowerCase()}`,
        process.env.NEXT_PUBLIC_BASE_URL
      )
    }

    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.DELETE_CART_SUCCESS })
    Cookies.remove('ssCartProducts')
    console.log('deleteToCart err', err)
    throw err
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const applyProDiscount =
  (domain = null) =>
  async (dispatch, getState) => {
    const { userReducer,cartReducer } = getState()
    const region = getCurrentRegion()
    const cartRegion = getCartLocale(region)
    const groupNameInfo =
      userReducer && userReducer.membership && userReducer.membership.group
        ? getGroupNameInfo(userReducer.membership.group)
        : null

    console.log('apply pro discount', region, userReducer.membership, cartRegion)

    dispatch({ type: types.APPLY_CART_PRO_REQUEST })

    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })

    try {
      const { data, Header } = await CartClient.put(`/cart/applyProDiscount`, {
        region,
        cartRegion,
        groupNameInfo,
      })

      const cartCookie = Header['Set-Cookie']
      document.cookie = cartCookie
      const localizedCartCookie = cartCookie.replace(
        'bc_cartId',
        `bc_cartId_${country.storeSource.toLowerCase()}`
      )
      document.cookie = localizedCartCookie

      const newCartItems = data.line_items.physical_items.map((item) => ({
        ...item,
        added_to_wishlist: false,
      }))

      const newCart = orderingCartItems(newCartItems, newCartItems)

      dispatch({
        type: types.APPLY_CART_PRO_SUCCESS,
        payload: data,
      })

      dispatch({
        type: types.LOAD_CART_SUCCESS,
        payload: {
          ...data,
          all_cart_items: newCart,
        },
      })

      dispatch({
        type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST,
        payload: getCartCurrencySymbol(),
      })
    } catch (err) {
      // Handle Error Here
      dispatch({ type: types.APPLY_CART_PRO_SUCCESS })
      console.log('applyProDiscount err', err)
    } finally {
      dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
    }
  }

export const removeProDiscount =
  (domain = null) =>
  async (dispatch) => {
    if (domain) {
      dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })
    }
    dispatch({ type: types.REMOVE_CART_PRO_REQUEST })
    try {
      const { data } = await CartClient.put(`/cart/removeProDiscount`, { domain })
      dispatch({
        type: types.REMOVE_CART_PRO_SUCCESS,
        payload: data,
      })

      dispatch({
        type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST,
        payload: getCartCurrencySymbol(),
      })
    } catch (err) {
      // Handle Error Here
      dispatch({ type: types.REMOVE_CART_PRO_SUCCESS })
      console.log('applyProDiscount err', err)
    } finally {
      dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
    }
  }

export const updateAllCartItems = (newAllCartItems) => async (dispatch) => {
  dispatch({ type: types.UPDATE_ALL_CART_ITEMS_REQUEST })

  try {
    dispatch({
      type: types.UPDATE_ALL_CART_ITEMS_SUCCESS,
      payload: newAllCartItems,
    })

    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.UPDATE_ALL_CART_ITEMS_FAILURE })
    console.log('updateAllCartItems err', err)
  }
}

export const getCartCheckoutRedirectUrl = async () => {
  try {
    const data = await CartClient.get(`/checkout-url`, {
      params: { user_locale_cache: getCurrentUserDomain() },
    })
    Cookies.remove('redirect_checkout')
    return data
  } catch (err) {
    // Handle Error Here
    console.log('getCartCheckoutRedirectUrl err', err)
    throw err
  }
}

export const updateCartShippingLocation =
  (country, existingCart = []) =>
  async (dispatch) => {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })

    try {
      const { data, Header } = await CartClient.post(`/cart/changeShippingLocation`, {
        country,
        user_locale_cache: getCurrentUserDomain(),
      })

      const newCartItems = data.line_items.physical_items

      const newCart = orderingCartItems(newCartItems, existingCart)

      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          all_cart_items: newCart,
        },
      })

    console.log('change shipping location', Header)
    const cartCookie = Header['Set-Cookie']
    document.cookie = cartCookie
    const localizedCartCookie = cartCookie.replace(
      'bc_cartId',
      `bc_cartId_${country.storeSource.toLowerCase()}`
    )

    document.cookie = localizedCartCookie

    dispatch({ type: types.UPDATE_CART_CURRENCY_SYMBOL_REQUEST, payload: getCartCurrencySymbol() })
  } catch (err) {
    // Handle Error Here
    console.log('updateCartShippingLocation err', err)
    throw err
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const setCartRegion = async () => {
  try {
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    let storeSource = country.storeSource.toLowerCase()

    const { data } = await CartClient.post(`/cart/set-region`, {
      storeSource,
    })
  } catch (error) {
    console.log('setCartRegion err', error)
  }
}

export const getCartDiscounts = async (cart, fields) => {
  try {
    let cartDiscountIds = getDiscountIdsFromCartItems(cart?.line_items?.physical_items)

    if (!cartDiscountIds) {
      return null
    }

    const response = await CartClient.post('/promotions', {
      cartDiscountIds: JSON.stringify(cartDiscountIds),
      fields: JSON.stringify(fields),
    })

    return response
  } catch (error) {
    console.log('getCartDiscounts err', error)
    return null
  }
}
